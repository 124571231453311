import { map } from 'lodash';
import { createSlice } from '@reduxjs/toolkit'; // redux bu
import { dispatch } from '../../store';
// utils
import axios from '../../../utils/axios';
import { CONSTS } from '../const';
import { MapDeckglOverlay } from '../../../components/map';
import { GetCreateOrderRequestModel } from '../../../@types/GetCreateORderRequestModel';
import {
  AddOrderRequestModel,
  TCMBModel
} from '../../../@types/AddOrderRequestModel';
import {
  getCustomerAllOrderRequest,
  getGetCustomerOrderRequestForStaffById,
  getWayOrderRequest
} from './orderRequest';
// ----------------------------------------------------------------------

type GetCreateOrderRequestState = {
  isLoading: boolean;
  error: boolean;
  detailModalIsOpen: boolean;
  GetCreateOrderRequestList: GetCreateOrderRequestModel[]; // farklı olan diğerleri fix
  GetCreateOrderRequest: GetCreateOrderRequestModel;
  orderRequestAddList: AddOrderRequestModel[];
  orderRequestAdd: AddOrderRequestModel;
  TCMBModel: TCMBModel;
};
const GetCreateOrderRequestInit = {
  // gelen modelin propertylerin tipleri
  trailerTypeId: 0,
  customersSelectList: [],
  customerLocationsSelectList: [],
  transportTypeSelectList: [],
  cargoTypeSelectList: [],
  cargoUnitSelectList: [],
  vehicleTypes: []
};
const TCMBModelInit = {
  eu: '',
  usd: ''
};
const AddOrderRequestInit = {
  customerId: 0,
  sourceLocationId: 0,
  destinationLocationId: 0,
  deliveryDate: '',
  cargoAmount: 0,
  cargoUnitId: 0,
  cargoDimesions: '',
  vehicleTypeId: 0,
  note: '',
  cargoTypeId: 0,
  width: 0,
  height: 0,
  depth: 0,
  transportTypeId: 0,
  cargoTypeOtherDesc: '',
  ladingName: '',
  trailerTypeId: 0,
  paymentMethotId: 0,
  senderCustomerId: 0,
  buyerCustomerId: 0,
  dangerousMaterialsTransportTypeId: 0,
  unNumber: 0,
  orderUnitPrice: 0
};
const initialState: GetCreateOrderRequestState = {
  // redux a bağlanıyor böyle
  isLoading: false,
  error: false,
  detailModalIsOpen: false,
  GetCreateOrderRequestList: [], // diğerleri fix
  GetCreateOrderRequest: GetCreateOrderRequestInit,
  orderRequestAddList: [], // buda öyle announcementInit yukarıda
  orderRequestAdd: AddOrderRequestInit,
  TCMBModel: TCMBModelInit
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // // GET PROFILE
    // getAllGetCreateOrderRequestSuccess(state, action) {
    //   // her  metod için 2 tane error ve succes metodları yazdık
    //   state.isLoading = false;
    //   state.GetCreateOrderRequestList = action.payload;
    // },
    getAllGetCreateOrderRequestByIdSuccess(state, action) {
      // her  metod için 2 tane error ve succes metodları yazdık
      state.isLoading = false;
      state.GetCreateOrderRequest = action.payload;
    },
    TCMBModelSuccess(state, action) {
      // her  metod için 2 tane error ve succes metodları yazdık
      state.isLoading = false;
      state.TCMBModel = action.payload;
    },
    // // GET PROFILE
    // filterGetCreateOrderRequestSuccess(state, action) {
    //   state.isLoading = false;
    //   state.GetCreateOrderRequestList = action.payload;
    // },
    // // GET PROFILE
    // saveGetCreateOrderRequestSuccess(state, action) {
    //   state.isLoading = false;
    //   state.GetCreateOrderRequestList = action.payload;
    // },
    saveOrderRequestSuccess(state, action) {
      state.isLoading = false;
      state.orderRequestAdd = action.payload;
      state.orderRequestAddList = action.payload;
    },
    // // GET PROFILE
    // getGetCreateOrderRequestByIdSuccess(state, action) {
    //   state.isLoading = false;
    //   state.GetCreateOrderRequestList = action.payload;
    // },
    onToggleDetailModal(state, action) {
      state.detailModalIsOpen = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleDetailModal } = slice.actions;

// ----------------------------------------------------------------------

export function GetTCMB() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONSTS.getTCMB}`);
      dispatch(slice.actions.TCMBModelSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function GetAllCreateOrderRequest(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      if (id !== null && id !== undefined) {
        const response = await axios.get(
          `${CONSTS.GetCreateOrderRequest}?customerId=${id}`
        );
        if (response)
          dispatch(
            slice.actions.getAllGetCreateOrderRequestByIdSuccess(
              response.data.data
            )
          );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function saveOrderRequest(orderRequestAdd: any) {
  const customerId = localStorage.getItem('Customer');
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(CONSTS.AddCustomerOrderRequest, {
      customerId: Number(customerId),
      sourceLocationId: Number(orderRequestAdd.sourceLocationId),
      DestinationLocationId: Number(orderRequestAdd.destinationLocationId),
      deliveryDate: orderRequestAdd.deliveryDate,
      cargoAmount: Number(orderRequestAdd.cargoAmount),
      cargoUnitId: Number(orderRequestAdd.cargoUnitId),
      cargoDimesions: orderRequestAdd.cargoDimesions,
      vehicleTypeId: Number(orderRequestAdd.vehicleTypeId),
      note: orderRequestAdd.note,
      cargoTypeId: Number(orderRequestAdd.cargoTypeId),
      width: Number(orderRequestAdd.width),
      height: Number(orderRequestAdd.height),
      depth: Number(orderRequestAdd.depth),
      transportTypeId: orderRequestAdd.transportTypeId,
      cargoTypeOtherDesc: orderRequestAdd.cargoTypeOtherDesc,
      ladingName: orderRequestAdd.ladingName,
      senderCustomerId: orderRequestAdd.senderCustomerId,
      buyerCustomerId: orderRequestAdd.buyerCustomerId,
      dangerousMaterialsTransportTypeId:
        orderRequestAdd.dangerousMaterialsTransportTypeId,
      trailerTypeId: Number(orderRequestAdd.trailerTypeId),
      paymentTypeId: Number(orderRequestAdd.paymentMethotId),
      unNumber: orderRequestAdd.unNumber,
      orderUnitPrice: orderRequestAdd.orderUnitPrice,
      currencyType: orderRequestAdd.currencyType,
      foreignCurrencyAmount: orderRequestAdd.foreignCurrencyAmount
    });
    if (response.data.success) {
      //    dispatch(getCustomerAllOrderRequest());

      return response.data.success;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function saveOrderRequestForStaff(orderRequestAdd: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(CONSTS.AddCustomerOrderRequest, {
      customerId: Number(orderRequestAdd.customerId),
      sourceLocationId: Number(orderRequestAdd.sourceLocationId),
      DestinationLocationId: Number(orderRequestAdd.destinationLocationId),
      deliveryDate: orderRequestAdd.deliveryDate,
      cargoAmount: Number(orderRequestAdd.cargoAmount),
      cargoUnitId: Number(orderRequestAdd.cargoUnitId),
      cargoDimesions: orderRequestAdd.cargoDimesions,
      vehicleTypeId: Number(orderRequestAdd.vehicleTypeId),
      note: orderRequestAdd.note,
      cargoTypeId: Number(orderRequestAdd.cargoTypeId),
      width: Number(orderRequestAdd.width),
      height: Number(orderRequestAdd.height),
      depth: Number(orderRequestAdd.depth),
      transportTypeId: orderRequestAdd.transportTypeId,
      cargoTypeOtherDesc: orderRequestAdd.cargoTypeOtherDesc,
      ladingName: orderRequestAdd.ladingName,
      senderCustomerId: orderRequestAdd.senderCustomerId,
      buyerCustomerId: orderRequestAdd.buyerCustomerId,
      dangerousMaterialsTransportTypeId:
        orderRequestAdd.dangerousMaterialsTransportTypeId,
      trailerTypeId: Number(orderRequestAdd.trailerTypeId),
      paymentTypeId: Number(orderRequestAdd.paymentMethotId),
      unNumber: orderRequestAdd.unNumber,
      orderUnitPrice: orderRequestAdd.orderUnitPrice,
      currencyType: orderRequestAdd.currencyType,
      foreignCurrencyAmount: 1
    });
    if (response.data.success) {
      dispatch(getWayOrderRequest());
      return response.data.success;
    }

    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}
