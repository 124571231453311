// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: 22, height: 22 }}
  />
);

const ICONS = {
  map: getIcon('ic_map'),
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  page: getIcon('ic_page'),
  user: getIcon('ic_user'),
  copy: getIcon('ic_copy'),
  error: getIcon('ic_error'),
  charts: getIcon('ic_charts'),
  editor: getIcon('ic_editor'),
  upload: getIcon('ic_upload'),
  animate: getIcon('ic_animate'),
  calendar: getIcon('ic_calendar'),
  elements: getIcon('ic_elements'),
  carousel: getIcon('ic_carousel'),
  language: getIcon('ic_language'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  components: getIcon('ic_components'),
  authenticator: getIcon('ic_authenticator'),
  home: getIcon('ic_home'),
  vehicleConnect: getIcon('ic_vehicle_connect'),
  vehicleForCities: getIcon('ic_vehiclec_for_cities'),
  orderRequest: getIcon('ic_OrderRequest'),
  userLocations: getIcon('ic_user_locations'),
  priceList: getIcon('ic_price_list'),
  staff: getIcon('ic_staff'),
  customer: getIcon('ic_customer'),
  contract: getIcon('ic_contact'),
  escalation: getIcon('ic_escalation'),
  vehicle: getIcon('ic_vehicle'),
  company: getIcon('ic_company'),
  organizations: getIcon('ic_organization'),
  waitShipping: getIcon('ic_wait_shipping')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Nakliyat',
    items: [
      {
        role: 'Customer',
        title: 'Ana Sayfa',
        href: PATH_DASHBOARD.transportation.StaffDashboard,
        icon: ICONS.home
      },
      {
        role: 'ShippingPersonnel',
        title: 'Ana Sayfa',
        href: PATH_DASHBOARD.transportation.StaffDashboard,
        icon: ICONS.home
      },
      {
        role: 'HumanResources',
        title: 'Ana Sayfa',
        href: PATH_DASHBOARD.transportation.StaffDashboard,
        icon: ICONS.home
      },
      {
        role: 'Accounting',
        title: 'Ana Sayfa',
        href: PATH_DASHBOARD.transportation.StaffDashboard,
        icon: ICONS.home
      },
      {
        role: 'Staff',
        title: 'Ana Sayfa',
        href: PATH_DASHBOARD.transportation.StaffDashboard,
        icon: ICONS.home
      },
      {
        role: 'ChiefDriver',
        title: 'Ana Sayfa',
        href: PATH_DASHBOARD.transportation.StaffDashboard,
        icon: ICONS.home
      },
      {
        role: 'Customer',
        title: 'Müşteriler',
        href: PATH_DASHBOARD.transportation.CustomerInCustomer,
        icon: ICONS.customer
      },
      {
        role: 'Customer',
        title: 'Sipariş Talebi Oluşturma',
        href: PATH_DASHBOARD.transportation.customerScreen,
        icon: ICONS.orderRequest
      },
      {
        role: 'Customer',
        title: 'Tanımlı Konumlarım',
        href: PATH_DASHBOARD.transportation.customerInformation,
        icon: ICONS.userLocations
      },
      {
        role: 'Customer',
        title: 'Siparişlerim',
        href: PATH_DASHBOARD.order.orderRequest,
        icon: ICONS.orderRequest

        // items: [
        //   {
        //     title: 'Sipariş Taleplerim',
        //     href: PATH_DASHBOARD.order.orderRequest
        //   },
        //   {
        //     title: 'Sipariş Sevk Durumları',
        //     href: PATH_DASHBOARD.order.orderShippingStatuses
        //   }
        // ]
      },
      // {
      //   role: 'Customer',
      //   title: 'Siparişi Düzenle',
      //   href: PATH_DASHBOARD.transportation.createOrderEdit
      // },
      // NOTTTTTTT:   Şöyle Bir sıkıntımız var bu kullanmadıklarımızı yorum satırına alıyoruz ama belki url de girelebilir onun testi yapılsınnnnn.......
      {
        role: 'Customer',
        title: 'Fiyat Tanımlamalarım',
        href: PATH_DASHBOARD.transportation.prices,
        icon: ICONS.priceList
      },
      {
        role: 'Customer',
        title: 'Sözleşmeler',
        href: PATH_DASHBOARD.transportation.ContractList,
        icon: ICONS.contract
      },

      // {
      //   role: 'Staff',
      //   title: 'Müşteri Bilgilendirme Ekranı',
      //   href: PATH_DASHBOARD.transportation.personelcustomerInformation
      // },
      // {
      //   role: 'Staff',
      //   title: 'Fiyat Tanımlama',
      //   href: PATH_DASHBOARD.transportation.PriceDefinationScreen
      // },
      // {
      //   role: 'Staff',
      //   title: 'Yükü Bölme Ekranı',
      //   href: PATH_DASHBOARD.transportation.DivideLoad
      // },

      // {
      //   role: 'ShippingPersonnel',
      //   title: 'Sevkiyat Organizasyonu',
      //   href: PATH_DASHBOARD.transportation.ShipmentOrganization
      // },
      // {
      //   role: 'Staff',
      //   title: 'Sevkiyat Son Onay ',
      //   href: PATH_DASHBOARD.transportation.shipmentFinalConfirmation
      // },
      //   role: 'Staff',
      //   title: 'Sevkiyat Organizasyonu',
      //   href: PATH_DASHBOARD.transportation.ShipmentOrganization
      // },
      // {
      //   role: 'Staff',
      //   title: 'Sevkiyat Son Onay ',
      //   href: PATH_DASHBOARD.transportation.shipmentFinalConfirmation
      // },ece

      {
        role: 'HumanResources',
        title: 'Personeller',
        href: PATH_DASHBOARD.transportation.StaffList,
        icon: ICONS.staff
      },

      {
        role: 'Accounting',
        title: 'Müşteriler',
        href: PATH_DASHBOARD.transportation.Customers,
        icon: ICONS.home,

        items: [
          {
            title: 'Müşteriler',
            href: PATH_DASHBOARD.transportation.customerList,
            icon: ICONS.customer
          },
          {
            title: 'Sözleşmeler',
            href: PATH_DASHBOARD.transportation.ContractList,
            icon: ICONS.contract
          }
          // {
          //   title: 'Eskalasyon',
          //   href: PATH_DASHBOARD.transportation.EscalationScreen,
          //   icon: ICONS.escalation
          // }
        ]
      },

      {
        role: 'Accounting',
        title: 'Siparişler',
        href: PATH_DASHBOARD.transportation.OrderRequestFull,
        icon: ICONS.orderRequest
      },
      {
        role: 'Accounting',
        title: 'Araçlar',
        href: PATH_DASHBOARD.transportation.Vehicle,
        icon: ICONS.vehicle,
        items: [
          {
            title: 'Özmal Araçlar',
            href: PATH_DASHBOARD.transportation.SelfOwnedVehicleStaff,
            icon: ICONS.vehicle
          },
          {
            title: 'Yabancı Araçlar',
            href: PATH_DASHBOARD.transportation.ForeignVehicleStaff,
            icon: ICONS.vehicle
          },
          {
            title: 'Dorseler',
            href: PATH_DASHBOARD.transportation.TrailerList,
            icon: ICONS.vehicle
          }
        ]
      },

      {
        role: 'Staff',
        title: 'Müşteriler',
        href: PATH_DASHBOARD.transportation.Customers,
        icon: ICONS.customer,

        items: [
          {
            title: 'Müşteriler',
            href: PATH_DASHBOARD.transportation.customerList,
            icon: ICONS.customer
          },
          {
            title: 'Sözleşmeler',
            href: PATH_DASHBOARD.transportation.ContractList,
            icon: ICONS.contract
          }
          // {
          //   title: 'Eskalasyon',
          //   href: PATH_DASHBOARD.transportation.EscalationScreen,
          //   icon: ICONS.escalation
          // }
        ]
      },
      {
        role: 'Staff',
        title: 'Araçlar',
        href: PATH_DASHBOARD.transportation.Vehicle,
        icon: ICONS.vehicle,

        items: [
          {
            title: 'Özmal Araçlar',
            href: PATH_DASHBOARD.transportation.SelfOwnedVehicleStaff,
            icon: ICONS.vehicle
          },
          {
            title: 'Yabancı Araçlar',
            href: PATH_DASHBOARD.transportation.ForeignVehicleStaff,
            icon: ICONS.vehicle
          },
          // {
          //   title: 'Malzemeler',
          //   href: PATH_DASHBOARD.transportation.DebitMaterial
          // },
          {
            title: 'Dorseler',
            href: PATH_DASHBOARD.transportation.TrailerList,
            icon: ICONS.vehicle
          }
        ]
      },
      {
        role: 'Staff',
        title: 'Siparişler',
        href: PATH_DASHBOARD.transportation.OrderRequestFull,
        icon: ICONS.orderRequest
      },
      {
        role: 'ShippingPersonnel',
        title: 'Siparişler',
        href: PATH_DASHBOARD.transportation.OrderRequestFull,
        icon: ICONS.orderRequest
      },
      {
        role: 'Staff',
        title: 'Personeller',
        href: PATH_DASHBOARD.transportation.StaffList,
        icon: ICONS.staff
      },
      {
        role: 'Staff',
        title: 'Çalışılan Transit Firmalar',
        href: PATH_DASHBOARD.transportation.foreignTransitCompany,
        icon: ICONS.company
      },
      {
        role: 'Staff',
        title: 'Özmal Firmalar',
        href: PATH_DASHBOARD.transportation.SelfOWnedCompanies,
        icon: ICONS.company
      },
      {
        role: 'ChiefDriver',
        title: 'Özmal Araçlar',
        href: PATH_DASHBOARD.transportation.SelfOwnedVehicleStaff,
        icon: ICONS.vehicle
      },
      {
        role: 'ChiefDriver',
        title: 'Dorseler',
        href: PATH_DASHBOARD.transportation.TrailerList,
        icon: ICONS.vehicle
      },
      {
        role: 'ChiefDriver',
        title: 'Özmal Araç Bağlantısı',
        href: PATH_DASHBOARD.transportation.kanban,
        icon: ICONS.vehicleConnect
      },

      {
        role: 'ShippingPersonnel',
        title: 'Sevkiyat Organizasyonu',
        href: PATH_DASHBOARD.transportation.orderRequestOrganization,
        icon: ICONS.vehicleConnect
      },
      {
        role: 'ShippingPersonnel',
        title: 'Onay Bekleyen Sevkiyatlar',
        href: PATH_DASHBOARD.transportation.waitingOKShipmentList,
        icon: ICONS.waitShipping
      },
      {
        role: 'ChiefDriver',
        title: 'İllere Göre Araçlar',
        href: PATH_DASHBOARD.transportation.GetVehicleListWithCity,
        icon: ICONS.vehicleForCities
      },
      {
        role: 'SuperAdmin',
        title: 'Müşteriler',
        href: PATH_DASHBOARD.transportation.customerList,
        items: [
          {
            title: 'Müşteriler Listesi',
            href: PATH_DASHBOARD.transportation.CustomerAll
          },
          {
            title: 'Sözleşme bitiş Süresi Yaklaşanlar',
            href: PATH_DASHBOARD.transportation.CustomerContractFinish
          }
          // {
          //   title: 'Eskalasyon şartını sağlayanlar',
          //   href: PATH_DASHBOARD.transportation.CustomerEscalationCondition
          // }
        ]
      },
      // {
      //   role: 'SuperAdmin',
      //   title: 'Müşteriler .eskiiiii',
      //   href: PATH_DASHBOARD.transportation.customers
      // },
      {
        role: 'SuperAdmin',
        title: 'Personeller',
        href: PATH_DASHBOARD.transportation.customerList,
        items: [
          {
            title: 'Personeller Listesi',
            href: PATH_DASHBOARD.transportation.ManagementStaffs
          },
          {
            title: 'Belge Bitiş Süresi Yaklaşanlar',
            href: PATH_DASHBOARD.transportation.StaffsContractFinished
          },
          {
            title: 'Çalışılan Transit Firmalar',
            href: PATH_DASHBOARD.transportation.WorkedTransitCompanies
          }
        ]
      },

      {
        role: 'SuperAdmin',
        title: 'Araçlar',
        href: PATH_DASHBOARD.transportation.customerList,
        items: [
          {
            title: 'Özmal Araçlar',
            href: PATH_DASHBOARD.transportation.SelfOwnedVehiclesManagementPanel
          },
          {
            title: 'Yabancı Araçlar',
            href: PATH_DASHBOARD.transportation.ForeignVehicles
          },
          {
            title: 'Araç Türleri',
            href: PATH_DASHBOARD.transportation.VehicleTypes
          },
          {
            title: 'Belge bitiş süresi yaklaşanlar',
            href: PATH_DASHBOARD.transportation.CarDocumentFinishedVehicles
          }
        ]
      }
      // {
      //   role: 'SuperAdmin',
      //   title: 'Araçlar Eskiiii',
      //   href: PATH_DASHBOARD.transportation.vehicles
      // }
    ]
  }
];

export default sidebarConfig;
