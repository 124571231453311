import { map } from 'lodash';
import { createSlice } from '@reduxjs/toolkit'; // redux bu
import { dispatch } from '../../store';
// utils
import axios from '../../../utils/axios';
import {
  addForeignVehicleDocumentModel,
  AddForeignVehicleModel,
  ForeignDocumentsSelectListModel,
  ForeignTransitCompanySelectListModel,
  foreignVehicleDocumentModel,
  GetForeignVehicleCompanyInformationModel,
  GetForeignVehicleDriverInformationModel,
  GetForeignVehicleModel,
  TransitCompanyEditModel,
  TransitCompanyListModel,
  TransitCompanyModel,
  VehiclesForTransitCompanyModel
} from '../../../@types/GetForeignVehicle';
import { CONSTS } from '../const';
import { MapDeckglOverlay } from '../../../components/map';
// ----------------------------------------------------------------------

type ForeignVehicleState = {
  isLoading: boolean;
  error: boolean;
  detailModalIsOpen: boolean;
  isDataLoad: boolean;
  GetForeignVehicleList: GetForeignVehicleModel[]; // farklı olan diğerleri fix
  getForeignVehicle: GetForeignVehicleModel;
  ForeignvechileDetailModalOpen: false;
  FovehicleId: number;
  GetForeignVehicleCompanyInformation: GetForeignVehicleCompanyInformationModel;
  GetForeignVehicleDriverInformation: GetForeignVehicleDriverInformationModel;
  GetForeignVehicleDriverInformationList: GetForeignVehicleDriverInformationModel[];
  ForeignPlate: string;
  foreignVehicleDocumentList: foreignVehicleDocumentModel[];
  vehicleId: Number;
  documentId: number;
  addForeignVehicleDocument: addForeignVehicleDocumentModel;
  foreignVehicleDocumentEditModalOpen: false;
  ForeignTransitCompanySelectList: ForeignTransitCompanySelectListModel[];
  AddForeignVehicle: AddForeignVehicleModel;
  ForeignDocumentsSelectList: ForeignDocumentsSelectListModel[];
  ForeignTransitCompanyModalOpen: false;
  TransitCompanyList: TransitCompanyListModel[];
  addTransitCompany: TransitCompanyModel;
  TransitCompanyByTransitCompanyId: TransitCompanyEditModel;
  transitCompanyName: string;
  transitCompanyId: number;
  vehiclesListForTransitCompany: VehiclesForTransitCompanyModel[];
};
const foreignvehicleInıt = {
  // gelen modelin propertylerin tipleri
  foreignVehicleId: 0,
  licensePate: '',
  vehicleTypeName: '',
  vehicleId: 0,
  foreignTransitAddress: '',
  foreignTransitCompanyName: '',
  cargoCapacity: 0,
  foreignTransitPhoneNumber: '',
  authorizedPersonel: '',
  foreignTransitCompaniesCurrentCode: 0,
  carmanName: '',
  carmanSurname: '',
  carmanPhoneNumber: '',
  vehicleIsFull: false,
  vehicleIsReady: false,
  documentStatüs: false,
  isPastDocument: true,
  isDocument: '',
  currentLocation: ''
};

const GetForeignVehicleCompanyInformationInıt = {
  vehicleLicensePlate: '',
  trailerLicensePlate: '',
  foreignTransitCompanyName: '',
  foreignTransitCompanyCurrentCode: 0,
  foreignTransitCompanyId: 0,
  vehicleId: 0,
  vehicleTypeId: 0,

  carmanId: 0,
  carmanName: '',
  carmanSurname: '',
  carmanTitleId: 0,
  carmanNameAndSurname: '',
  trailerTypeId: 0,
  trailerId: 0,
  trailerTypeName: '',
  trailerCargoCapacity: 0,
  drivertckNo: '',
  driverEmail: '',
  driverName: '',
  driverSurname: '',
  driverNameAndSurname: '',
  driverId: 0,
  driverCityId: 0,
  driverDistrctId: 0,
  driverCityName: '',
  driverDistrictName: '',
  driverPhone: ''
};

const GetForeignVehicleDriverInformationInıt = {
  carmanId: 0,
  tckn: '',
  nameAndSurname: '',
  phoneNumber: '',
  name: '',
  surname: ''
};

const addForeignVehicleDocumentInıt = {
  documentTypeId: 0,
  beginDate: '',
  endDate: '',
  documentImageFileBase64String: '',
  documentImageName: '',
  vehicleId: 0,
  staffId: 0
};

const addForeignVehicleInıt = {
  vehicleLicensePlate: '',
  foreignTransitCompanyId: 0,
  currentCode: 0,
  chieferDriverId: 0,
  trailerLicensePlate: '',
  trailerCargoCapacity: 0,
  trailerTypeId: 0,
  vehicleTypeId: 0,
  tckNo: '',
  email: '',
  name: '',
  surname: '',
  phone: '',
  cityId: 0,
  districtId: 0
};

const TransitCompanyAddInıt = {
  companyName: '',
  phone: '',
  authorizedPersonnel: '',
  currentCode: 0,
  cityId: 0,
  address: '',
  districtId: 0
};

const TransitCompanyEditInıt = {
  address: '',
  authorizedPersonel: '',
  cityId: 0,
  cityName: '',
  companyName: '',
  currentCode: 0,
  districtId: 0,
  districtName: '',
  phoneNumber: '',
  transitCompanyId: 0
};

const initialState: ForeignVehicleState = {
  // redux a bağlanıyor böyle
  isLoading: false,
  error: false,
  detailModalIsOpen: false,
  GetForeignVehicleList: [], // diğerleri fix
  getForeignVehicle: foreignvehicleInıt,
  ForeignvechileDetailModalOpen: false,
  FovehicleId: 0,
  GetForeignVehicleCompanyInformation: GetForeignVehicleCompanyInformationInıt,
  GetForeignVehicleDriverInformation: GetForeignVehicleDriverInformationInıt,
  GetForeignVehicleDriverInformationList: [],
  ForeignPlate: '',
  foreignVehicleDocumentList: [],
  vehicleId: 0,
  documentId: 0,
  addForeignVehicleDocument: addForeignVehicleDocumentInıt,
  foreignVehicleDocumentEditModalOpen: false,
  ForeignTransitCompanySelectList: [],
  isDataLoad: false,
  AddForeignVehicle: addForeignVehicleInıt,
  ForeignDocumentsSelectList: [],
  ForeignTransitCompanyModalOpen: false,
  TransitCompanyList: [],
  addTransitCompany: TransitCompanyAddInıt,
  TransitCompanyByTransitCompanyId: TransitCompanyEditInıt,
  transitCompanyName: '',
  vehiclesListForTransitCompany: [],
  transitCompanyId: 0
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // START LOADING
    startDLoading(state) {
      state.isDataLoad = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getAllForeignVehicleSuccess(state, action) {
      // her  metod için 2 tane error ve succes metodları yazdık
      state.isLoading = false;
      state.GetForeignVehicleList = action.payload;
    },

    // GET PROFILE
    filterForeignVehicleSuccess(state, action) {
      state.isLoading = false;
      state.GetForeignVehicleList = action.payload;
    },
    // GET PROFILE
    saveForeignVehicleSuccess(state, action) {
      state.isLoading = false;
      state.GetForeignVehicleList = action.payload;
    },

    // GET PROFILE
    getForeignVehicleByIdSuccess(state, action) {
      state.isLoading = false;
      state.getForeignVehicle = action.payload;
    },

    setFoVehicleId(state, action) {
      state.isLoading = false;
      state.FovehicleId = action.payload;
    },
    setForeignPlate(state, action) {
      state.isLoading = false;
      state.ForeignPlate = action.payload;
    },
    setVehicleId(state, action) {
      state.isLoading = false;
      state.vehicleId = action.payload;
    },

    setdocumentId(state, action) {
      state.isLoading = false;
      state.documentId = action.payload;
    },

    setTransitCompanyId(state, action) {
      state.transitCompanyId = action.payload;
    },
    getGetForeignVehicleCompanyInformation(state, action) {
      state.isLoading = false;
      state.isDataLoad = false;
      state.GetForeignVehicleCompanyInformation = action.payload;
    },
    GetForeignVehicleDriverInformationSuccess(state, action) {
      state.isLoading = false;
      state.GetForeignVehicleDriverInformationList = action.payload;
    },

    getForeignVehicleDokumentsSuccess(state, action) {
      state.isLoading = false;
      state.foreignVehicleDocumentList = action.payload;
    },
    onToggleDetailModal(state, action) {
      state.detailModalIsOpen = action.payload;
    },
    onToggleForeignVechileDetailModalOpen(state, action) {
      state.ForeignvechileDetailModalOpen = action.payload;
    },
    setForeignVehicleDocumentEditModalOpen(state, action) {
      state.foreignVehicleDocumentEditModalOpen = action.payload;
    },
    getForeignTransitCompanySelectListSuccess(state, action) {
      state.ForeignTransitCompanySelectList = action.payload;
    },
    getForeignVehicleDocumentTypeSelectListSuccess(state, action) {
      state.ForeignDocumentsSelectList = action.payload;
    },
    setForeignTransitCompanyModalOpen(state, action) {
      state.ForeignTransitCompanyModalOpen = action.payload;
    },
    getAllTransitCompanyListSuccess(state, action) {
      state.TransitCompanyList = action.payload;
    },
    getTransitCompanyByTransitCompanyIdSuccess(state, action) {
      state.TransitCompanyByTransitCompanyId = action.payload;
    },
    settransitCompanyName(state, action) {
      state.transitCompanyName = action.payload;
    },
    getVehiclesForTransitCompanyListSuccess(state, action) {
      state.vehiclesListForTransitCompany = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  onToggleDetailModal,
  onToggleForeignVechileDetailModalOpen,
  setFoVehicleId,
  setForeignPlate,
  setVehicleId,
  setdocumentId,
  setForeignVehicleDocumentEditModalOpen,
  setForeignTransitCompanyModalOpen,
  settransitCompanyName,
  setTransitCompanyId
} = slice.actions;

// ----------------------------------------------------------------------

export function addPriceDefination(model: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${CONSTS.AnnouncementAddAnnouncement}`,
        {
          title: model.title,
          description: model.description,
          publicationDate: model.publicationDate,
          announcementTypeId: Number(model.announcementTypeId),
          takedownDate: model.takedownDate
        }
      );
      localStorage.setItem('id', response.data.data.id);

      dispatch(getAllForeignVehicle());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllForeignVehicle() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(CONSTS.GeForeignVehicle, {});
      dispatch(slice.actions.getAllForeignVehicleSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGetForeignVehicleCompanyInformation(id: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startDLoading());

    try {
      const response = await axios.get(
        `${CONSTS.getForeignVehicleCompanyInformation}?vehicleId=${id}`
      );
      dispatch(
        slice.actions.getGetForeignVehicleCompanyInformation(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function updateForeignVehicleInformation(updatemodel: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      CONSTS.updateForeignVehicleInformation,
      updatemodel
    );
    if (response.data.success) {
      dispatch(getGetForeignVehicleCompanyInformation(updatemodel.vehicleId));
      return true;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export function GetForeignVehicleDriverInformationByFoId(id: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.GetForeignVehicleDriverInformation}?foreignVehicleId=${id}`
      );
      dispatch(
        slice.actions.GetForeignVehicleDriverInformationSuccess(
          response.data.data
        )
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getForeignVehicleDokuments(id: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.getForeignVehicleDokuments}?vehicleId=${id}`
      );
      dispatch(
        slice.actions.getForeignVehicleDokumentsSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function addForeignNewVehicleDocument(model: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(CONSTS.addForeignVehicleDocument, model);
    if (response.data.success) {
      dispatch(getForeignVehicleDokuments(model.vehicleId));
      return response.data;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function removeForeignVehicle(id: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(`${CONSTS.deleteForeignVehicle}`, id);
    if (response.data.success) {
      dispatch(getAllForeignVehicle());
      return true;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export function getForeignTransitCompanySelectList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        CONSTS.getForeignTransitCompanySelectList,
        {}
      );
      dispatch(
        slice.actions.getForeignTransitCompanySelectListSuccess(
          response.data.data
        )
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function addForeignNewVehicle(model: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(CONSTS.addForeignVehicle, {
      vehicleLicensePlate: model.vehicleLicensePlate,
      foreignTransitCompanyId: model.foreignTransitCompanyId,
      currentCode: Number(model.currentCode),
      chieferDriverId: Number(model.chieferDriverId),
      trailerLicensePlate: model.trailerLicensePlate,
      trailerCargoCapacity: Number(model.trailerCargoCapacity),
      trailerTypeId: Number(model.trailerTypeId),
      vehicleTypeId: Number(model.vehicleTypeId),
      tckNo: model.tckNo,
      email: model.email,
      name: model.name,
      surname: model.surname,
      phone: model.phone,
      cityId: Number(model.cityId),
      districtId: Number(model.districtId),
      chiefPassword: model.chiefPassword
    });
    if (response.data) {
      // dispatch(getForeignVehicleDokuments(response.data.vehicleId));
      return response.data;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export function getForeignVehicleDocumentTypeSelectList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        CONSTS.getForeignVehicleDocumentTypesSelectList,
        {}
      );
      dispatch(
        slice.actions.getForeignVehicleDocumentTypeSelectListSuccess(
          response.data.data
        )
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllTransitCompanyList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(CONSTS.transitCompanyList, {});
      dispatch(
        slice.actions.getAllTransitCompanyListSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function addTransitCompanyInformation(model: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      CONSTS.addTransitCompanyInformation,
      model
    );

    if (response.data.success) {
      dispatch(getAllTransitCompanyList());
      return response.data;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return null;
  }
}

export function getTransitCompanyByTransitCompanyId(id: number) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(
        `${CONSTS.getTransitCompanyByTransitCompanyId}?Id=${id}`
      );
      dispatch(
        slice.actions.getTransitCompanyByTransitCompanyIdSuccess(
          response.data.data
        )
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function updateTransitCompanyByTransitCompanyId(updatemodel: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      CONSTS.updateTransitCompanyInfo,
      updatemodel
    );
    if (response.data.success) {
      dispatch(getAllTransitCompanyList());
      return true;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function removeTransitCompany(id: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(`${CONSTS.deleteTransitCompany}`, id);
    if (response.data) {
      // dispatch(getAllForeignVehicle());
      return response.data;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export function getVehiclesForTransitCompanyList(id: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.getVehiclesForTransitCompanyList}?foreignTaransitCompanyId=${id}`
      );
      dispatch(
        slice.actions.getVehiclesForTransitCompanyListSuccess(
          response.data.data
        )
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
